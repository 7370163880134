import React from "react";
import { graphql } from "gatsby";

import { Helmet } from "react-helmet";

import Header from "../layouts/header";
import Footer from "../layouts/footer";
import Path from "../components/path";
import Cookies from "../components/cookies";
import { removeHardSpace } from "../components/helpers";

import { currentSections } from "../data/mapSections"; // eslint-disable-line

import ListPostIntro from "../layouts/listPostsIntro";

import { mapListPostIntro } from "../data/sectionsMapMethods";
import NewsLetter from "../layouts/newsletter";
import Hero from "../layouts/hero/hero-primary";

import { formatStringToDateString } from "../components/helpers";
import BlogPost from "../layouts/blogPost";
import BannerButton from "../components/banner-button";

export default function TrainingPostTemplate({ data }) {
  const blogPost = data?.wpPost;

  const seo = blogPost?.seo;

  const menuItems = data?.wpMenu?.menuItems?.nodes;
  const footerProps = data?.wp?.footerSettings;
  const footerLinks = data.allWpMenu?.edges;

  const postType = blogPost?.categories?.nodes[0]?.name;

  const allTrainings = mapListPostIntro({
    title: "Zobacz również",
    typeList: postType === "Sponsoring" ? "sponsoring" : "handbooks",
    buttonMoreLabel:
      postType === "Sponsoring"
        ? "Przeczytaj więcej"
        : "Przeczytaj więcej porad",
    buttonMoreHref: postType === "Sponsoring" ? "/sponsoring" : "/poradniki",
    theme: "light",
  });

  const shareData = {
    title: seo?.title,
  };

  const privateBreadcrumbs = [
    seo?.breadcrumbs[0],
    {
      text: postType === "Sponsoring" ? "Sponsoring" : "Poradniki",
      url: postType === "Sponsoring" ? "/sponsoring" : "/poradniki",
    },
    {
      ...seo?.breadcrumbs[1],
      url: seo?.breadcrumbs[1]?.url.replace("poradnik/", "poradniki/"),
    },
    seo?.breadcrumbs[2],
  ];

  // TO DO PORADNIK -> PORADNIKI

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "pl",
        }}
      >
        {/* <link rel="stylesheet" href="https://use.typekit.net/fyu0edh.css" /> */}
        <meta charset="UTF-8" />

        <title>{removeHardSpace(seo?.title)}</title>
        <meta name="description" content={seo?.metaDesc} />
        <meta name="keywords" content={seo?.metaKeywords} />

        <meta property="og:title" content={seo?.opengraphTitle} />
        <meta property="og:type" content={seo?.opengraphType} />
        <meta
          property="og:url"
          content={seo?.opengraphUrl.replace("/poradnik/", "/poradniki/")}
        />
        <meta property="og:image" content={seo?.opengraphImage?.sourceUrl} />
        <meta property="og:site_name" content={seo?.opengraphSiteName} />

        <meta name="twitter:title" content={seo?.twitterTitle} />
        <meta name="twitter:description" content={seo?.twitterDescription} />
        <meta name="twitter:image" content={seo?.twitterImage} />
      </Helmet>

      <Header links={menuItems} variant={"light"} />
      <main>
        <Path list={privateBreadcrumbs} variant={"light"} />
        <Hero
          data={{
            headlines: { main: blogPost.title },
            label: blogPost.tags?.nodes[0]?.name,
            image: blogPost.featuredImage?.node,
            postInfo: {
              author: blogPost.author?.node?.name,
              created: formatStringToDateString(blogPost.date),
            },
          }}
        />
        <BlogPost data={blogPost} />
        <BannerButton
          data={shareData}
          labelType={blogPost?.groupPost?.shareType}
        />
        {blogPost?.groupPost?.showAnotherPosts && (
          <ListPostIntro data={allTrainings} />
        )}
        <NewsLetter />
      </main>
      <Footer data={{ footerProps, footerLinks }} />
      <Cookies />
    </>
  );
}

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { in: [$id] }) {
      id
      title
      excerpt
      content
      status
      slug
      featuredImage {
        node {
          altText
          sizes
          sourceUrl
          srcSet
          slug
        }
      }
      tags {
        nodes {
          slug
          name
          id
          taxonomyName
        }
      }
      categories {
        nodes {
          slug
          name
          taxonomyName
        }
      }
      id
      authorId
      author {
        node {
          name
        }
      }
      date
      modified
      uri
      modifiedGmt
      dateGmt
      seo {
        title
        twitterDescription
        twitterTitle
        twitterImage {
          sourceUrl
        }
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphSiteName
        opengraphImage {
          sourceUrl
        }
        metaKeywords
        metaDesc
        breadcrumbs {
          text
          url
        }
      }
      groupPost {
        showAnotherPosts
        shareType
      }
    }

    wp {
      generalSettings {
        url
        title
        description
        language
      }
      footerSettings {
        settings {
          description
          address
          address2
          copyright
          createdBy
          socials {
            fieldGroupName
            icon
            url
          }
        }
      }
    }
    wpMenu(name: { in: "Menu top" }) {
      name
      menuItems {
        nodes {
          id
          label
          order
          url
          path
          target
          parent {
            id
          }
          parentId
          connectedNode {
            node {
              ... on WpPage {
                id
                groupPage {
                  menuIcon {
                    altText
                    sourceUrl
                  }
                }
                pageOptions {
                  label
                  disableView
                  disableButtonFly
                }
              }
            }
          }
        }
      }
      parent {
        id
        ... on WpPage {
          id
        }
      }
      nodeType
    }

    allWpMenu {
      edges {
        node {
          id
          count
          name
          slug
          menuItems {
            nodes {
              order
              title
              uri
              path
              parentId
              id
              label
              connectedNode {
                node {
                  ... on WpPage {
                    id
                    pageOptions {
                      label
                      disableView
                      disableButtonFly
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
