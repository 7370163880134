import * as React from "react";

import Section from "@components/section";

import * as styles from "./blog-post.module.css";

import "./remote-style.css";

const BlogPost = (props) => {
  const { data } = props;

  return (
    <Section
      className={styles.post}
      innerClassName={styles.post__inner}
      innerVariant={"alt"}
      theme={"light"}
    >
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
    </Section>
  );
};

export default BlogPost;
