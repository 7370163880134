import * as React from "react";
import { useState, useEffect } from "react";

import { FacebookShareButton, LinkedinShareButton } from "react-share";

import * as styles from "./banner-button.module.css";

import LinkedIn from "../../images/svg/Linkedin.inline.svg";
import Facebook from "../../images/svg/Facebook.inline.svg";

const initLabels = {
  default: "Podziel się wiedzą",
  custom: "Udostępnij ten wpis",
};

const BannerButton = (props) => {
  const { title } = props.data;
  const { labelType } = props;

  const [pageUrl, setPageUrl] = useState(null);

  useEffect(() => {
    setPageUrl(document.location.href);
  }, []);

  return (
    <div className={styles.banner}>
      <div className={styles.banner__inner}>
        <h2 className={styles.label}>{initLabels[labelType || "default"]}</h2>
        <div className={styles.socials}>
          <LinkedinShareButton
            url={pageUrl}
            title={title}
            className={styles.icon}
          >
            <LinkedIn />
          </LinkedinShareButton>
          <FacebookShareButton
            url={pageUrl}
            quote={title}
            className={styles.icon}
          >
            <Facebook />
          </FacebookShareButton>
        </div>
      </div>
    </div>
  );
};

export default BannerButton;
